var app
$(function () {
  let h = $(window).height()
  $('.siderContent').css('height', h + 'px')
  app = new Vue({
    el: '#nav-container',
    data: function data() {
      return {
        isHome: window.location.pathname,
        isLoading: false,
        showAddress: false,
        provinceList: addressList,
        // 省市列表
        cityList: [],
        // 区列表
        streetList: [],
        // 街道列表
        provinceActive: null,
        // 省市激活样式
        cityActive: null,
        // 区激活
        streetActive: null,
        // 街道激活
        cityValue: $.cookie('areaName'),
        // 区值
        streetValue: '',
        // 街道值
        provinceValue: '',
        //省市值
        areaValue: $.cookie('areaName'),
        // 办理地区值
        areaNo: $.cookie('areaId'),
        //地区号
        queryTypeList: [],
        //search搜索出来的分类
        queryTypeStatus: false,
        searchVal: '',
        cityValueCopy: '',
        show: false,
        shower: false,
        apkShow: false,
        qrcodeUser: '/images/wechart.jpg',
        screen: document.body.clientWidth,
        userEr: false,
        //用户
        downEr: false,
        //下载
        sinaEr: false,
        //新浪
        aboutEr: false,
        //关于我们
        publicEr: false,
        //公众号
        serverUrl: '', //下载apk
        userUrl: '',
        find: false
      }
    },
    mounted: function mounted() {
      // alert(8888)
      this.find = true
      this.isLoading = true
      var that = this
      that.getUriVer()
      window.onresize = function () {
        return (function () {
          window.screenWidth = document.body.clientWidth
          that.screen = window.screenWidth
          that.messageUpdate()
        })()
      }
    },
    methods: {
      //获取下载apk地址
      getUriVer: function getUriVer() {
        var _this = this
        return
        $.ajax({
          url:
            'https://package.fuqim.com/app/appUriVer.json?v=' +
            new Date().getTime(),
          success: function success(data) {
            _this.userUrl = data.user.android_url
            _this.serverUrl = data.server.android_url
          }
        })
      },
      //messageUpdate
      messageUpdate: function messageUpdate() {
        this.$nextTick(function () {
          $('#qrcodeUser,#weiqrcodeUser').attr(
            'src',
            '/images/wechart.jpg'
          )
        })
      },
      // 地址确认
      addressConfirm: function addressConfirm() {
        this.areaValue = this.provinceValue + this.cityValue + this.streetValue
        if (this.cityValue) {
          $.cookie('areaName', this.cityValue, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaId', this.areaNo, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaProvince', this.provinceValue, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaDistrict', this.cityValue, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          this.showAddress = false
          // 异步刷新招标中心列表以及指数数据
          this.refreshData()
        } else if (this.provinceValue) {
          $.cookie('areaName', this.provinceValue, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaId', this.areaNo, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaProvince', this.provinceValue, {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaDistrict', '', {
            path: '/',
            expires: 1238400000,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          this.cityValueCopy = ''
          this.cityValue = this.provinceValue
          this.showAddress = false
          // 异步刷新招标中心列表以及指数数据
          this.refreshData()
        } //  else {
        //     showAlert('#alert2', '市级地址为必选项')
        // }
      },
      // 选择省市
      selectProvince: function selectProvince(item, index) {
        this.areaNo = item.areaId
        this.provinceActive = index
        this.provinceValue = item.areaName
        this.streetList = []
        this.cityActive = null
        this.streetActive = null
        this.cityValue = ''
        this.streetValue = ''
        if (this.provinceValue === '\u5168\u56FD') this.cityList = []
        if (item.areaDictionaryVOList && item.areaDictionaryVOList != []) {
          this.cityList = item.areaDictionaryVOList
        }
      },
      // 选择市区
      selectCity: function selectCity(item, index) {
        this.areaNo = item.areaId
        this.cityActive = index
        this.cityValue = item.areaName
        this.cityValueCopy = item.areaName
        this.streetActive = null
        this.streetValue = ''
        if (item.areaDictionaryVOList && item.areaDictionaryVOList != []) {
          this.streetList = item.areaDictionaryVOList
        }
      },
      // 选择街道
      selectStreet: function selectStreet(item, index) {
        this.areaNo = item.areaId
        this.streetActive = index
        this.streetValue = item.areaName
      },
      // 清空地址
      clearAddress: function clearAddress() {
        this.cityList = []
        this.streetList = []
        this.provinceActive = null
        this.cityActive = null
        this.streetActive = null
        this.provinceValue = $.cookie('areaName')
        this.areaNo = $.cookie('areaId')
        this.cityValue = ''
        this.streetValue = ''
      },
      // 异步刷新招标中心列表以及指数数据
      refreshData: function refreshData() {
        console.log(82222222222222222222)
        if (typeof vmProject != 'undefined') {
          var index = $('.tab ul li.active').data('index')
          vmProject.getProjectList(index, null, 1, null, null)
          vmProject.getTrade()
        }
        if (typeof vm != 'undefined') {
          var city = ''
          if (this.cityValue === '\u5168\u56FD') {
            city = ''
          } else {
            city = this.cityValueCopy != '' ? this.cityValue : ''
          }
          vm.areaValue = this.provinceValue + city
        }
      },
      //搜索类别
      searchTypeFn: function searchTypeFn(e) {
        try {
          if (e.target.value != '') {
            this.throttle(this.queryTypeDataFn, null, 500, e.target.value)
          }
        } catch (error) { }
      },
      hideSearchType: function hideSearchType() {
        var _this = this
        setTimeout(function () {
          _this.queryTypeStatus = false
        })
      },
      getSearchType: function getSearchType(item) {
        window.location.href = '/product/' + item.productNo
      },
      //节流
      throttle: function throttle(fn, context, delay, text) {
        clearTimeout(fn.timeoutId)
        fn.timeoutId = setTimeout(function () {
          fn.call(context, text)
        }, delay)
      },
      // 修改参数zlk
      queryTypeDataFn: function queryTypeDataFn(val) {
        var _this = this
        var queryMap = {
          keyword: val,
          pageNo: 1,
          pageSize: 10
        }
        $.post('/getSearchKeyword', queryMap, function (result) {
          if (result.content === null) {
            $('.oneq').show()
          } else {
            $('.oneq').hide()
          }
          if (result.content !== null) {
            if (result.code == 0) {
              var data = result.content.productsInfos
              if (data.length === 0) {
                $('.oneq').show()
              } else {
                $('.oneq').hide()
              }
              _this.queryTypeList = data
              _this.queryTypeStatus = true
            }
          }
        })
      },
      searchFn: function searchFn() {
        let url = `search?keyword=${this.searchVal}&searchTabindex=1`
        console.log('url123', url)
        window.open($("#URL").text() + url)
      }
    }
  })
  $('.hadoop')
    .parent()
    .stop()
    .hover(
      function () {
        $('.hadoopView')
          .stop()
          .show()
      },
      function () {
        $('.hadoopView')
          .stop()
          .hide()
      }
    )
  $('.hadoopView').click(function () {
    $('.hadoopView').hide()
  })
  $('.download-app').click(function () {
    toast("赋企猫APP正在努力开发中~您可以扫描屏幕右侧小程序二维码，使用小程序登录移动端。", 2000)
  })
  //右边框动画
  $('.minTools').click(function () {
    var dataType = $(this).attr('data-type')
    if (dataType == 'open') {
      $(this).addClass('open')
      $('.rightSideBar').animate({ right: '0' }, 300)
      dataType = $(this).attr('data-type', 'close')
    } else {
      $(this).removeClass('open')
      $('.rightSideBar').animate({ right: '-46px' }, 300)
      dataType = $(this).attr('data-type', 'open')
    }
  })
  //出世进来检测屏幕大小
  if (app.screen > 1530) {
    // alert(888)
    $('.minTools')
      .click()
      .hide()
  } else {
    $('.rightSideBar').animate({ right: '-46px' }, 300)
  }
  //监测窗口大小
  $(window).resize(function () {
    if (app.screen > 1530) {
      $('.rightSideBar').animate({ right: 0 }, 300)
    } else {
      $('.rightSideBar').css('right', '-46px')
    }
  })
  if (!$.cookie('token')) {
    $('.dengluView1').show()
    $('.dengluView2').hide()
  } else {
    $('.dengluView2').show()
    $('.dengluView1').hide()
  }
  // 左侧导航 地址判断
  if ($.cookie('token')) {
    $('.sideBarTop li:nth-child(1) a').attr('href', $('#URL').text() + 'users')
  } else {
    $('.sideBarTop li:nth-child(1) a').attr('href', $('#URL').text() + '/login')
  }
  // 右侧导航
  $('#qrcodeUser,#weiqrcodeUser').attr('src', '/public/images/wechart.jpg')

  //视频控制开关
  // $('.close').click(function () {
  //   $('.mask1').removeClass('active')
  //   $('.two').removeClass('active')
  //   $('.mask3').removeClass('active')
  //   $('.video1')[0].pause()
  //   $('.video2')[0].pause()
  //   $('.video3')[0].pause()
  // })
  // $('.total li')
  //   .eq(0)
  //   .click(function () {
  //     $('.mask1').addClass('active')
  //     $('.video1')[0].play()
  //   })
  // $('.total li')
  //   .eq(1)
  //   .click(function () {
  //     $('.two').addClass('active')
  //     $('.video2')[0].play()
  //   })
  // $('.total li')
  //   .eq(2)
  //   .click(function () {
  //     $('.mask3').addClass('active')
  //     $('.video3')[0].play()
  //   })
  var authSuccess = false
  getServerAuthenticationInfo()

  function getServerAuthenticationInfo() {
    let params = {}
    $.ajax({
      type: 'post',
      url: '/getServerAuthenticationInfo',
      dataType: 'json',
      data: params,
      success: function success(result) {
        if (result.code == 0) {
          if (result.content.authStatus == 2) {
            //已认证
            if ($.cookie('token')) {
              authSuccess = true
              authing = false
              $('.asServerAlert a').removeAttr('href')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          } else if (result.content.authStatus == 1) {
            //审核中
            if ($.cookie('token')) {
              authing = true
              authSuccess = false
              $('.asServerAlert a').removeAttr('href')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          } else if (result.content.authStatus == 3) {
            if ($.cookie('token')) {
              authing = false
              authSuccess = false
              $('.asServerAlert a').attr('href', '/serverAuthentication')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          } else {
            if ($.cookie('token')) {
              authing = false
              authSuccess = false
              $('.asServerAlert a').attr('href', '/serverAuthentication')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          }
        }
      }
    })
  }

  //划过入口显示
  $('.videoer').hover(
    function () {
      $('.total').addClass('active')
    },
    function () {
      $('.total').removeClass('active')
    }
  )
  $('.total').hover(
    function () {
      $('.total').addClass('active')
    },
    function () {
      $('.total').removeClass('active')
    }
  )

  $('.aboutus-a').hover(
    function () {
      $('.about-us').css('display', 'flex')
      $('.servers').css('display', 'none')
    }
  )

  $('.about-us').hover(
    function () {
      $('.about-us').css('display', 'flex')
    },
    function () {
      $('.about-us').css('display', 'none')
    }
  )


  $('.servers-a').hover(
    function () {
      $('.servers').css('display', 'flex')
      $('.about-us').css('display', 'none')
    }
  )

  $('.servers').hover(
    function () {
      console.log('authSuccess', authSuccess)
      $('.servers').css('display', 'flex')
    },
    function () {
      $('.servers').css('display', 'none')
    }
  )

  $('.be-servers').click(() => {
    console.log("?????", authSuccess)
    if (authSuccess) {
      busDialog()
    } else {
      if ($.cookie('token')) {
        window.open('/serverAuthentication', "_blank")
      } else {
        console.log("????????")
        window.location.href = "/login"
      }

    }

  })

  function busDialog() {
    var _busDialog = $('#busDialoga')
    console.log(_busDialog[0])
    if (!_busDialog[0]) {

      var str = '<div id="busDialoga">'
      str +=
        '<img src="/images/server-authentication/authSuccessbg.png"/><span><img src="/images/busdialog/index-close.png"/></span>'
      str += '</div>'
      $('body').append(str)
      $('<style>')
        .prop('type', 'text/css')
        .html(
          '\
              #busDialoga{\
                  display:none;\
                  position: fixed;\
                  z-index: 1000;\
                  top:50%;\
                  left:50%;\
                  -webkit-transform: translate(-50%, -50%);\
                  transform: translate(-50%, -50%);\
              }\
              #busDialoga img{\
                  display:block;\
                  width:689px;\
                  height:430px;\
              }\
              #busDialoga span{\
                  position:absolute;\
                  right: 10px;\
                  top: 10px;\
                  display:block;\
                  width:46px;\
                  height:46px;\
                  cursor:pointer;\
              }\
              #busDialoga .dialog-btn{\
                  position:absolute;\
                  left:19px;\
                  bottom:51px;\
              }\
              #busDialoga .dialog-btn .a1{\
                  width: 110px;\
                  height: 35px;\
                  display: inline-block;\
                  padding: 10px 0 0 34px;\
                  margin-left: 40px;\
                  background: url(/images/busdialog/btn_bg.png) no-repeat 10px center;\
                  background-size: 22px 22px;\
                  color: #fff;\
                  background-color: #546aad;\
                  border-radius: 6px;\
              }\
              #busDialoga .dialog-btn .a1:hover{\
                  background-color: #7488C7;\
              }\
              #busDialoga .dialog-btn .a2{\
                  width:110px;\
                  height:35px;\
                  display:inline-block;\
                  padding:10px 0 0 30px;\
                  background: url(/images/busdialog/fa_btn.png) no-repeat 10px center;\
                  background-size: 22px 22px;\
                  margin-left:50px;\
                  border-radius: 6px;\
                  background-color:#FF751A;\
                  color:#fff;\
              }\
              #busDialoga .dialog-btn .a2:hover{\
                  background-color:#FF9852;\
              }\
              #busDialoga span img{\
                  display:block;\
                  width:14px;\
                  height:14px;\
                  cursor:pointer;\
              }\
          '
        )
        .appendTo('head')

      console.log($('#busDialoga')[0])
    }

    $('#busDialoga span').on('click', function () {
      $(this)
        .parent()
        .fadeOut()
    })

    $('#busDialoga').fadeIn()

    // }, 30)
  }


  // banner
  var mySwiper = new Swiper('.swiper-container-nav', {
    autoplay: true,//可选选项，自动滑动
    direction: 'vertical',
    loop: true,
    speed: 300,
  })

  //视频播放控制
  $('.indexmovieContent ul li').click(function () {
    let index = $(".indexmovieContent ul li").index(this);
    $('.videoBoxs').eq(index).css('display', 'block').find('video')[0].play()
  })
  $('.closeIcon').click(function () {
    let index = $('.closeIcon').index(this)
    $('.videoBoxs').eq(index).find('video')[0].pause()
    $('.videoBoxs').eq(index).css('display', 'none')

  })

  $('.closeIcon1').click(function () {
    $('.refreshVideo').toggle();
  })
})
